@import styles/sass/abstracts



.headContent
  +res-md-min
    display: flex
    justify-content: space-between
    align-items: center
    margin-top: 30px
  +res-sm-max
    margin-top: 20px

.author
  display: flex
  +typoTextSm
  margin-right: 30px
  > *
    &:not(:last-child)
      margin-right: 7px
  strong
    font-weight: $fw-black

.tags
  display: flex
  flex-wrap: wrap
  margin-left: auto
  > *
    margin-bottom: 8px
  +res-md-min
    justify-content: flex-end
    max-width: 50%
    > *
      &:not(:first-child)
        margin-left: 10px
  +res-sm-max
    margin-top: 20px
    > *
      &:not(:last-child)
        margin-right: 10px

.shareTop
  margin-top: 20px

.main
  margin-top: 60px 
  margin-bottom: 60px 

.shareBottom 
  margin-top: 40px

.prodottiConsigliati
  margin-bottom: 60px

.articoliCorrelati
  background: $neutral-1
  padding: 50px 0 
  +res-md-max
    padding: 30px 0
