@import styles/sass/abstracts

.shareTop
  margin-top: 30px

.shareBottm
  margin-top: 50px
  margin-bottom: 50px

.main
  margin-top: 60px 
  margin-bottom: 60px 

.bottom
  margin-bottom: 60px 